import firebase from "firebase";

var config = {
  apiKey: "AIzaSyBirYHJN3ulDB9ayRNn_rWCPDsdATX5EXo",
  authDomain: "mmgame-2cec3.firebaseapp.com",
  projectId: "mmgame-2cec3",
  storageBucket: "mmgame-2cec3.appspot.com",
  databaseURL: "https://mmgame-2cec3.firebaseio.com",
  messagingSenderId: "99756780827",
  appId: "1:99756780827:web:9cce403b8f68478de337be",
  measurementId: "G-VW51JFG76P"
};

firebase.initializeApp(config);

// const db = firebaseApp.firestore();
export default firebase;