<template>
  <v-container fill-height fluid class="x-ctn">
    <v-row align="center" justify="center">
      <div class="display-1 text-center">
        Creating new game... <br /><br />
        <v-progress-circular indeterminate color="green"></v-progress-circular>
      </div>
    </v-row>
  </v-container>
</template>

<script>
import firebase from "../Firebase";

export default {
  name: "Home",
  components: {},
  data() {
    return {
      ref: firebase.firestore(),
    };
  },
  created() {
    const gameId = Math.random()
      .toString(36)
      .substr(2, 6);
    this.ref
      .collection("/games")
      .doc(gameId)
      .set({ players: [], weapons: [], kills: [] })
      .then(() => {
        this.$router.push(`/games/${gameId}`);
      });
  },
};
</script>
